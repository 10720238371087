const axios = require('axios');
const base_url = process.env.REACT_APP_BASE_API_URL;
// const base_url = 'https://apiv2-398476.backoffice.gg/api/v1'

export const getAsync = async(url, header = null, responseType = null) => {
    try {
        var headers = {};
        headers['Content-Type'] = 'application/json; charset=utf-8';
        if (header)
            headers = {...headers, ...header };

        var resp = await axios({
            method: 'GET',
            headers: headers,
            url: `${base_url}/${url}`,
            responseType: responseType
        });

        return resp.data;
    } catch (err) {
        console.log(err);
        throw err;
    }
};

export const postAsync = async(url, payload, header = null) => {
    try {
        var headers = { 'Content-Type': 'application/json; charset=utf-8' };
        if (header)
            headers = Object.assign(headers, header);
        var resp = await axios({
            headers: headers,
            method: 'POST',
            url: `${base_url}/${url}`,
            data: payload
        });

        return resp.data;
    } catch (err) {
        console.log(err);
        throw err;
    }
};
export const deleteAsync = async(url, payload, header = null) => {
    try {
        var headers = { 'Content-Type': 'application/json; charset=utf-8' };
        if (header)
            headers = {...headers, ...header };

        var resp = await axios({
            method: 'DELETE',
            url: `${base_url}/${url}`,
            headers: headers,
            data: payload
        });

        return resp.data;
    } catch (err) {
        console.log(err);
        throw err;
    }
};
export const formPostAsync = async(url, frmData, header = null, jwtToken = null) => {
    try {
        var headers = { 'Content-Type': 'multipart/form-data' };
        if (jwtToken)
            headers['Authorization'] = `Bearer ${jwtToken}`;
        if (header)
            headers = Object.assign(headers, header);
        var resp = await axios({
            headers: headers,
            method: 'POST',
            url: `${base_url}/${url}`,
            data: frmData
        });

        return resp.data;
    } catch (err) {
        console.log(err);
        throw err;
    }
};

export const getUrl = () => base_url;