import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Row, Container, Col } from 'react-bootstrap';
import PivotTableV2 from './components/PivotTableV2.jsx';

const axios = require('axios');

const postAsync = async (url, payload, header = null) => {
  const base_url = 'https://apiv2-398476.backoffice.gg/api/v1';

  try {
    var headers = { 'Content-Type': 'application/json; charset=utf-8' };
    if (header) headers = Object.assign(headers, header);
    const token = localStorage.getItem('newApiToken');

    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }
    var resp = await axios({
      headers: headers,
      method: 'POST',
      url: `${base_url}/${url}`,
      data: payload,
    });

    return resp.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

const formatDate = (timestamp) => {
  const date = new Date(timestamp);
  return new Intl.DateTimeFormat('en-GB', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  }).format(date);
};

const LedgerTransactionRecords = () => {
  const [searchParams] = useSearchParams();
  const [responseData, setResponseData] = useState();
  const [tableDataConfig, setTableDataConfig] = useState([]);

  const hasParams = Array.from(searchParams.keys()).length > 0;

  const paramsObject = Object.fromEntries(searchParams.entries());

  useEffect(() => {
    const init = async () => {
      const response = await postAsync('treasure/excel-record', {
        ...paramsObject,
        date: new Date(paramsObject.date).toISOString(),
      });
      setResponseData(response?.result);
    };

    init();
    responseDataConfig();
  }, [hasParams]);

  const responseDataConfig = () => {
    if (paramsObject.category === 'byClient') {
      setTableDataConfig([
        {
          dataIndex: 'recordDate',
          label: 'Date',
          render: (record) => <>{formatDate(record.recordDate)}</>,
        },
        { dataIndex: 'bankName', label: 'Bank Account' },
        { dataIndex: 'bankRegion', label: 'Country Code' },
        { dataIndex: 'bankCode', label: 'Bank Code' },
        { dataIndex: 'currency', label: 'Currency' },
        { dataIndex: 'boCode', label: 'Client Code' },
        { dataIndex: 'description', label: 'Description' },
        { dataIndex: 'balance', label: 'Balance' },
      ]);
    }
    if (paramsObject.category === 'byCountry') {
      setTableDataConfig([
        {
          dataIndex: 'recordDate',
          label: 'Date',
          render: (record) => <>{formatDate(record.recordDate)}</>,
        },
        { dataIndex: 'bankName', label: 'Bank Account' },
        { dataIndex: 'bankRegion', label: 'Country Code' },
        { dataIndex: 'bankCode', label: 'Bank Code' },
        { dataIndex: 'currency', label: 'Currency' },
        { dataIndex: 'boCode', label: 'Client Code' },
        { dataIndex: 'payeeOrRemitter', label: 'Payee/Remitter' },
        { dataIndex: 'description', label: 'Description' },
        { dataIndex: 'debit', label: 'Debit' },
        { dataIndex: 'balance', label: 'Balance' },
      ]);
    }

    if (paramsObject.category === 'byCountryBank') {
      setTableDataConfig([
        {
          dataIndex: 'recordDate',
          label: 'Date',
          render: (record) => <>{formatDate(record.recordDate)}</>,
        },
        { dataIndex: 'bankName', label: 'Bank Account' },
        { dataIndex: 'bankRegion', label: 'Country Code' },
        { dataIndex: 'bankCode', label: 'Bank Code' },
        { dataIndex: 'currency', label: 'Currency' },
        { dataIndex: 'boCode', label: 'Client Code' },
        { dataIndex: 'payeeOrRemitter', label: 'Payee/Remitter' },
        { dataIndex: 'description', label: 'Description' },
        { dataIndex: 'debit', label: 'Debit' },
        { dataIndex: 'balance', label: 'Balance' },
      ]);
    }
    if (paramsObject.category === 'byCurrency') {
      setTableDataConfig([
        {
          dataIndex: 'recordDate',
          label: 'Date',
          render: (record) => <>{formatDate(record.recordDate)}</>,
        },
        { dataIndex: 'bankName', label: 'Bank Account' },
        { dataIndex: 'bankRegion', label: 'Country Code' },
        { dataIndex: 'bankCode', label: 'Bank Code' },
        { dataIndex: 'currency', label: 'Currency' },
        { dataIndex: 'boCode', label: 'Client Code' },
        { dataIndex: 'payeeOrRemitter', label: 'Payee/Remitter' },
        { dataIndex: 'description', label: 'Description' },
        { dataIndex: 'debit', label: 'Debit' },
        { dataIndex: 'balance', label: 'Balance' },
      ]);
    }
    if (paramsObject.category === 'byName') {
      setTableDataConfig([
        {
          dataIndex: 'recordDate',
          label: 'Date',
          render: (record) => <>{formatDate(record.recordDate)}</>,
        },
        { dataIndex: 'bankName', label: 'Bank Account' },
        { dataIndex: 'bankRegion', label: 'Country Code' },
        { dataIndex: 'bankCode', label: 'Bank Code' },
        { dataIndex: 'currency', label: 'Currency' },
        { dataIndex: 'boCode', label: 'Client Code' },
        { dataIndex: 'payeeOrRemitter', label: 'Payee/Remitter' },
        { dataIndex: 'description', label: 'Description' },
        { dataIndex: 'debit', label: 'Debit' },
        { dataIndex: 'balance', label: 'Balance' },
      ]);
    }
    if (paramsObject.category === 'byType') {
      setTableDataConfig([
        {
          dataIndex: 'recordDate',
          label: 'Date',
          render: (record) => <>{formatDate(record.recordDate)}</>,
        },
        { dataIndex: 'bankName', label: 'Bank Account' },
        { dataIndex: 'bankRegion', label: 'Country Code' },
        { dataIndex: 'bankCode', label: 'Bank Code' },
        { dataIndex: 'currency', label: 'Currency' },
        { dataIndex: 'boCode', label: 'Client Code' },
        { dataIndex: 'description', label: 'Description' },
        { dataIndex: 'debit', label: 'Debit' },
        { dataIndex: 'balance', label: 'Balance' },
      ]);
    }
  };

  return (
    <Container fluid>
      <Row>
        <Col lg={12}>
          <Row style={{ marginBottom: '8px' }}>
            <span className="p-2 pageheader">
              <h3 className="pagetitle">Ledger Transaction Records</h3>
            </span>
          </Row>
        </Col>
      </Row>
      <PivotTableV2
        dataSource={responseData?.items}
        dataConfig={tableDataConfig}
      />
    </Container>
  );
};

export default LedgerTransactionRecords;
