import React from 'react';
import styles from '../Table.module.css';
import { numberWithCommas } from '../../../helpers/common';

// const greenFontColor =

export const renderCell = (item, dataIndex) => {
  const value = item[dataIndex];

  const spanStyle = () => {
    if (dataIndex === 'movement' && value > 0) {
      return { color: 'green' };
    }
    if (dataIndex === 'movement' && value < 0) {
      return { color: 'red' };
    }
  };
  return (
    <>
      {value ? (
        <span style={spanStyle()}>{numberWithCommas(value)}</span>
      ) : (
        <span className={styles.emptyCell}>No Record</span>
      )}
    </>
  );
};
