import React, { useEffect, useState } from 'react';
import styles from './Table.module.css';
import { renderCell } from './utils/renderCell';
import { numberWithCommas } from '../../helpers/common';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';

const PivotTableV2 = ({
  dataSource,
  dataConfig,
  grandTotal,
  prevGrandTotal,
  movementTotal,
}) => {
  const [sortedDataSource, setSortedDataSource] = useState(dataSource);

  useEffect(() => {
    setSortedDataSource(dataSource);
  }, [dataSource]);

  const showGrandTotal = (index) => {
    if (index === 0) {
      return 'Grand Total';
    }

    if (index === dataConfig.length - 4) {
      return numberWithCommas(prevGrandTotal);
    }
    if (index === dataConfig.length - 3) {
      return numberWithCommas(grandTotal);
    }
    if (index === dataConfig.length - 2) {
      return numberWithCommas(movementTotal);
    }
  };

  const onClickHeaderSortingAsc = (dataIndex) => {
    const sortedData = [...dataSource].sort((a, b) => {
      const valueA = a[dataIndex];
      const valueB = b[dataIndex];

      if (valueA === null && valueB === null) return 0;
      if (valueA === null) return -1; // null is smaller
      if (valueB === null) return 1; // null is smaller

      if (typeof valueA === 'number' && typeof valueB === 'number') {
        return valueA - valueB;
      }

      if (typeof valueA === 'string' && typeof valueB === 'string') {
        return valueA.localeCompare(valueB);
      }

      return Number(valueA) - Number(valueB);
    });
    setSortedDataSource(sortedData);
  };

  const onClickHeaderSortingDesc = (dataIndex) => {
    const sortedData = [...dataSource].sort((a, b) => {
      const valueA = a[dataIndex];
      const valueB = b[dataIndex];

      if (valueA === null && valueB === null) return 0;
      if (valueA === null) return 1; // null is larger
      if (valueB === null) return -1; // null is larger

      if (typeof valueA === 'number' && typeof valueB === 'number') {
        return valueB - valueA;
      }

      if (typeof valueA === 'string' && typeof valueB === 'string') {
        return valueB.localeCompare(valueA);
      }

      return Number(valueB) - Number(valueA);
    });
    setSortedDataSource(sortedData);
  };

  return (
    <div className={styles.tableContainer}>
      <table className={styles.table}>
        <thead className={styles.tableHeader}>
          <tr>
            {dataConfig.map((config) => (
              <th key={config.dataIndex}>
                {config.label}
                {config.dataIndex !== 'action' && (
                  <>
                    <KeyboardDoubleArrowUpIcon
                      onClick={() => onClickHeaderSortingAsc(config.dataIndex)}
                      sx={{ fontSize: 20 }}
                    />
                    <KeyboardDoubleArrowDownIcon
                      onClick={() => onClickHeaderSortingDesc(config.dataIndex)}
                      sx={{ fontSize: 20 }}
                    />
                  </>
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className={styles.tableBody}>
          {sortedDataSource?.map((record, rowIndex) => (
            <tr key={rowIndex}>
              {dataConfig.map((config) => {
                if (config.render) {
                  return (
                    <td key={config.dataIndex}>
                      {config.render(record, rowIndex)}
                    </td>
                  );
                }
                return (
                  <td key={config.dataIndex}>
                    {renderCell(sortedDataSource[rowIndex], config.dataIndex)}
                  </td>
                );
              })}
            </tr>
          ))}
          {grandTotal && (
            <tr>
              {dataConfig.map((_, index) => (
                <>
                  <td style={{ fontWeight: 'bold' }} key={`empty-${index}`}>
                    {showGrandTotal(index)}
                  </td>
                </>
              ))}
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default PivotTableV2;
