import React, { useEffect, useState } from 'react';
import { Row, Container, Col, Modal } from 'react-bootstrap';
import {
  Select,
  OutlinedInput,
  MenuItem,
  ListItemText,
  TextField,
  Button,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import PivotTableV2 from './components/PivotTableV2.jsx';
import DateGridHelper from './components/utils/tableHelpers.js';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

const filterList = [
  { label: 'Client', value: 'byClient' },
  { label: 'Country', value: 'byCountry' },
  { label: 'Bank', value: 'byCountryBank' },
  { label: 'Currency', value: 'byCurrency' },
  { label: 'Name', value: 'byName' },
  { label: 'Account Type', value: 'byType' },
];

const axios = require('axios');

const postAsync = async (url, payload, header = null) => {
  const base_url = 'https://apiv2-398476.backoffice.gg/api/v1';

  try {
    var headers = { 'Content-Type': 'application/json; charset=utf-8' };
    if (header) headers = Object.assign(headers, header);
    const token = localStorage.getItem('newApiToken');

    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }
    var resp = await axios({
      headers: headers,
      method: 'POST',
      url: `${base_url}/${url}`,
      data: payload,
    });

    return resp.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

const SingleCategoryTable = () => {
  const [dashboardData, setDashboardData] = useState({
    portfolios: [],
  });
  const [byCountry, setByCountry] = useState([]);
  const [byCountryAndBank, setByCountryAndBank] = useState([]);
  const [byCurrency, setByCurrency] = useState([]);
  const [byCurrencyAndType, setByCurrencyAndType] = useState([]);
  const [showZoom, setShowZoom] = useState(false);
  const [zoomContent, setZoomContent] = useState();
  const [filters, setFilters] = useState({
    portfolio: {
      PortfolioId: '',
      PortfolioName: '',
    },
    monthYear: new Date(),
    countries: {
      countryList: [],
      countrySelected: [],
    },
    bankAndCountry: {
      list: [],
      selected: [],
    },
    currencies: {
      list: [],
      selected: [],
    },
    currencyType: {
      list: [],
      selected: [],
    },
  });
  const [responseData, setResponseData] = useState([]);
  const [categoryFilter, setCategoryFilters] = useState([{}]);
  const [currentCategory, setCurentCategory] = useState(filterList[0].value);
  const [filteredData, setFilteredData] = useState([]);
  const [tableDataConfig, setTableDataConfig] = useState([]);
  const [searchFilter, setSearchFilter] = useState('');

  const handleSearch = (property, searchValue) => {
    const searchedData = responseData[property]?.items;

    if (property === 'byClient') {
      const filtered = searchedData.filter((item) =>
        item.code.toLowerCase().includes(searchValue)
      );
      setFilteredData(filtered);
    }
    if (property === 'byCountry') {
      const filtered = searchedData.filter((item) =>
        item.region.toLowerCase().includes(searchValue)
      );
      setFilteredData(filtered);
    }
    if (property === 'byCountryBank') {
      const filtered = searchedData.filter((item) =>
        item.bankName.toLowerCase().includes(searchValue)
      );
      setFilteredData(filtered);
    }
    if (property === 'byCurrency') {
      const filtered = searchedData.filter((item) =>
        item.currency.toLowerCase().includes(searchValue)
      );
      setFilteredData(filtered);
    }
    if (property === 'byName') {
      const filtered = searchedData.filter((item) =>
        item.code.toLowerCase().includes(searchValue)
      );
      setFilteredData(filtered);
    }
    if (property === 'byType') {
      const filtered = searchedData.filter((item) =>
        item.type.toLowerCase().includes(searchValue)
      );
      setFilteredData(filtered);
    }
    setSearchFilter(searchValue);
  };

  const handleViewMoreRecords = async (record) => {
    if (currentCategory === 'byClient') {
      window.open(
        `/PMS/LedgerRecords?category=${currentCategory}&ClientCode=${record.code}&date=${filters.monthYear}`,
        '_blank'
      );
    }

    if (currentCategory === 'byCountry') {
      window.open(
        `/PMS/LedgerRecords?category=${currentCategory}&Region=${record.region}&date=${filters.monthYear}`,
        '_blank'
      );
    }
    if (currentCategory === 'byCountryBank') {
      window.open(
        `/PMS/LedgerRecords?category=${currentCategory}&Region=${record.region}&BankCode=${record.bankCode}&date=${filters.monthYear}`,
        '_blank'
      );
    }
    if (currentCategory === 'byCurrency') {
      window.open(
        `/PMS/LedgerRecords?category=${currentCategory}&Currency=${record.currency}&date=${filters.monthYear}`,
        '_blank'
      );
    }
    if (currentCategory === 'byName') {
      window.open(
        `/PMS/LedgerRecords?category=${currentCategory}&NameCode=${record.code}&date=${filters.monthYear}`,
        '_blank'
      );
    }
    if (currentCategory === 'byType') {
      window.open(
        `/PMS/LedgerRecords?category=${currentCategory}&Type=${record.type}&date=${filters.monthYear}`,
        '_blank'
      );
    }
  };

  const actionColumn = {
    dataIndex: 'action',
    label: 'Action',
    render: (record) => {
      return (
        <div style={{ width: '80%' }}>
          <button onClick={() => handleViewMoreRecords(record)}>
            View More
          </button>
          {/* {actionMenu ? (
            <div style={{ position: 'absolute', right: 10 }}>
              <MenuList>
                {filterList.map((filter) => (
                  <MenuItem>{filter.label}</MenuItem>
                ))}
              </MenuList>
            </div>
          ) : null} */}
        </div>
      );
    },
  };

  const responseDataConfig = () => {
    if (currentCategory === 'byClient') {
      setTableDataConfig([
        { dataIndex: 'code', label: 'Code' },
        { dataIndex: 'prevBalanceUSD', label: 'Previous Balance USD' },
        { dataIndex: 'currentBalanceUSD', label: 'Current Balance USD' },
        { dataIndex: 'movement', label: 'Movement' },
      ]);
    }
    if (currentCategory === 'byCountry') {
      setTableDataConfig([
        { dataIndex: 'region', label: 'Region' },
        { dataIndex: 'prevBalanceUSD', label: 'Previous Balance USD' },
        { dataIndex: 'currentBalanceUSD', label: 'Current Balance USD' },
        { dataIndex: 'movement', label: 'Movement' },
      ]);
    }
    if (currentCategory === 'byCountryBank') {
      setTableDataConfig([
        { dataIndex: 'region', label: 'Region' },
        { dataIndex: 'bankCode', label: 'Bank Code' },
        { dataIndex: 'bankName', label: 'Bank Name' },
        { dataIndex: 'prevBalanceUSD', label: 'Previous Balance USD' },
        { dataIndex: 'currentBalanceUSD', label: 'Current Balance USD' },
        { dataIndex: 'movement', label: 'Movement' },
      ]);
    }
    if (currentCategory === 'byCurrency') {
      setTableDataConfig([
        { dataIndex: 'currency', label: 'Currency' },
        { dataIndex: 'prevBalanceUSD', label: 'Previous Balance USD' },
        { dataIndex: 'currentBalanceUSD', label: 'Current Balance USD' },
        { dataIndex: 'movement', label: 'Movement' },
      ]);
    }
    if (currentCategory === 'byName') {
      setTableDataConfig([
        { dataIndex: 'category', label: 'Category' },
        { dataIndex: 'code', label: 'Code' },
        { dataIndex: 'type', label: 'Type' },
        { dataIndex: 'prevBalanceUSD', label: 'Previous Balance USD' },
        { dataIndex: 'currentBalanceUSD', label: 'Current Balance USD' },
        { dataIndex: 'movement', label: 'Movement' },
      ]);
    }
    if (currentCategory === 'byType') {
      setTableDataConfig([
        { dataIndex: 'type', label: 'Type' },
        { dataIndex: 'prevBalanceUSD', label: 'Previous Balance USD' },
        { dataIndex: 'currentBalanceUSD', label: 'Current Balance USD' },
        { dataIndex: 'movement', label: 'Movement' },
      ]);
    }
  };

  useEffect(() => {
    responseDataConfig();
    setFilteredData([]);
  }, [currentCategory]);

  useEffect(() => {
    const init = async () => {
      var results = await postAsync('dashboard/treasury', {
        date: filters.monthYear,
      });

      var pf =
        results.result.portfolios.length > 0
          ? results.result.portfolios[0]
          : { PortfolioId: '', PortfolioName: '' };
      filters.portfolio = pf;
      setFilters({ ...filters });
      // buildWidgetPattern(results.result, pf.portfolio_id);
      // bindStates(results.result, pf);
    };
    init();
  }, []);

  useEffect(() => {
    (async () => {
      var results = await postAsync('dashboard/treasury', {
        date: filters.monthYear,
      });
      // buildWidgetPattern(results.result, filters.portfolio.PortfolioId);
      // bindStates(results.result, filters.portfolio);
    })();
  }, [filters.portfolio]);

  useEffect(() => {
    const init = async () => {
      const results = await postAsync('dashboard/treasury', {
        date: filters.monthYear,
      });
      setResponseData(results.result);
    };

    init();
  }, [filters.monthYear, filters.portfolio]);

  /* Filtering */
  useEffect(() => {
    if (dashboardData.byCountry == null) return;
    setByCountry(
      dashboardData.byCountry.filter((row) =>
        filters.countries.countrySelected.includes(row.region)
      )
    );
  }, [filters.countries.countrySelected]);

  useEffect(() => {
    if (dashboardData.byCountryBank == null) return;
    setByCountryAndBank(
      dashboardData.byCountryBank.filter((row) =>
        filters.bankAndCountry.selected.includes(row.region)
      )
    );
  }, [filters.bankAndCountry.selected]);

  useEffect(() => {
    if (dashboardData.byCurrency == null) return;
    setByCurrency(
      dashboardData.byCurrency.filter((row) =>
        filters.currencies.selected.includes(row.currency)
      )
    );
  }, [filters.currencies.selected]);

  useEffect(() => {
    if (dashboardData.byCurrencyType == null) return;
    setByCurrencyAndType(
      dashboardData.byCurrencyType.filter((row) =>
        filters.currencyType.selected.includes(row.currency)
      )
    );
  }, [filters.currencyType.selected]);
  /* End Filtering */

  return (
    <Container fluid>
      <Modal
        className="dashboard-modal"
        show={showZoom}
        size="lg"
        top
        aria-labelledby="zoom-header"
        onHide={() => setShowZoom(false)}
      >
        <Modal.Body>
          <Row className="pms-dashboard">
            <Col lg={12}>{zoomContent}</Col>
          </Row>
        </Modal.Body>
      </Modal>
      <Row>
        <Col lg={12}>
          <Row style={{ marginBottom: '8px' }}>
            <span className="p-2 pageheader">
              <h3 className="pagetitle">Treasury Dashboard</h3>
            </span>
          </Row>
        </Col>
      </Row>

      <Row className="pms-dashboard" style={{ marginTop: '30px' }}>
        <Col lg={12} style={{ marginBottom: '20px' }}>
          <span style={{ color: 'white' }}>Filter by: </span>
          &nbsp;&nbsp;
          <Select
            value={currentCategory}
            onChange={(event) => {
              setCurentCategory(event.target.value);
            }}
            input={<OutlinedInput label={currentCategory} />}
            // renderValue={(selected) => filter[filterKey][textKey]}
            MenuProps={{
              PaperProps: {
                sx: {
                  '& .MuiMenuItem-root:hover': {
                    backgroundColor: 'lightgrey',
                    color: 'black',
                  },
                  '& .MuiMenuItem-root.Mui-selected:hover': {
                    backgroundColor: 'lightgrey',
                    color: 'black',
                  },
                  '& .MuiCheckbox-root': {
                    color: 'white',
                  },
                  '& .MuiList-root': {
                    background: 'rgb(31, 33, 37) !important',
                    color: 'white !important',
                    width: '250px',
                  },
                  '& .MuiListItemText-primary': {
                    fontSize: '13px !important',
                  },
                },
              },
            }}
            sx={{
              width: '250px',
              height: '27px',
              border: '1px solid #d9d9d9 !important',
              '.MuiButtonBase-root': {
                color: 'white',
              },
            }}
            style={{
              borderRadius: '4px',
              width: '200px !important',
              height: '32px !important',
              fontSize: '14px',
              left: '0px',
            }}
          >
            {filterList?.map((row, idx) => (
              <MenuItem key={idx} value={row.value}>
                <ListItemText primary={row.label} />
              </MenuItem>
            ))}
          </Select>
          &nbsp;&nbsp;
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label={'Month'}
              views={['month', 'year']}
              renderInput={(props) => (
                <TextField {...props} size="small" helperText={null} />
              )}
              onChange={(newValue) => {
                filters.monthYear = newValue;
                setFilters({ ...filters });
              }}
              InputProps={{
                sx: {
                  '&.MuiOutlinedInput-root': {
                    border: '1px solid #fff',
                    height: '27px',
                  },
                  '& .MuiInputLabel-outlined': {
                    border: '1px solid #FF0000 !important',
                  },
                },
              }}
              value={filters.monthYear}
              openTo="month"
            />
          </LocalizationProvider>
        </Col>
      </Row>
      <div style={{ display: 'flex' }}>
        <div style={{ marginTop: '30px', display: 'flex' }}>
          <span style={{ color: 'white', marginRight: '8px' }}>Search By:</span>
          {filterList?.map((row, idx) => (
            <div style={{ display: 'flex' }}>
              {row.value === currentCategory && (
                <TextField
                  size="small"
                  InputProps={{
                    sx: {
                      '&.MuiOutlinedInput-root': {
                        border: '1px solid #fff',
                        height: '27px',
                      },
                      '& .MuiInputLabel-outlined': {
                        border: '1px solid #FF0000 !important',
                        marginBottom: '12px',
                      },
                    },
                  }}
                  onChange={(e) => handleSearch(row.value, e.target.value)}
                  label={row.label}
                />
              )}
            </div>
          ))}
        </div>
        <Button
          size="small"
          variant="outlined"
          onClick={() =>
            DateGridHelper.exportToCsv(
              `Treasury-Reports-${new Date(filters.monthYear)}`,
              [...tableDataConfig],
              responseData[currentCategory]?.items
            )
          }
          endIcon={<FileDownloadIcon />}
          sx={{
            color: '#ffc107',
            // padding: '2px',
            fontSize: 12,
            marginTop: '1.7rem',
            marginLeft: '1.5rem',
            borderColor: '#ffc107',
            padding: '.5rem ',
            height: '1.9rem',
            alignItems: 'center',
          }}
        >
          Export CSV
        </Button>
      </div>
      {responseData.byClient && (
        <PivotTableV2
          dataSource={
            filteredData.length > 0
              ? filteredData
              : responseData[currentCategory]?.items
          }
          dataConfig={[...tableDataConfig, actionColumn]}
          grandTotal={responseData[currentCategory]?.grandTotal}
          prevGrandTotal={responseData[currentCategory]?.prevGrandTotal}
          movementTotal={responseData[currentCategory]?.movement}
        />
      )}
    </Container>
  );
};

export default SingleCategoryTable;
